import {Navigate, Route, Routes} from "react-router-dom";
import Navbar from "../layouts/Navbar";
import Login from "../pages/Login";
import Main from "../pages/Main";
import {UserContext} from "../utils/auth";
import {useState} from "react";
import RegisterNewGame from "../pages/RegisterNewGame";
import {Decimal} from "decimal.js";
import PlayerStatistics from "../pages/PlayerStatistics";

export default function NavigationWrapper() {
    const [players, setPlayers] = useState([
        {name: 'Andres', delta: new Decimal(0.0), issuer: false, opponent: false, pic: 'https://firebasestorage.googleapis.com/v0/b/whist-application.appspot.com/o/8A26ADE6-C272-4E03-8021-13E526F6FBF1_1_105_c.jpeg?alt=media&token=2e7546d4-6f08-4272-94c8-d311b1eb656c'},
        {name: 'Guy', delta: new Decimal(0.0), issuer: false, opponent: false, pic: 'https://firebasestorage.googleapis.com/v0/b/whist-application.appspot.com/o/guy.jpg?alt=media&token=35316640-5e9c-4507-8b82-8ac37c6c400a'},
        {name: 'Xandra', delta: new Decimal(0.0), issuer: false, opponent: false, pic: 'https://firebasestorage.googleapis.com/v0/b/whist-application.appspot.com/o/xandra.jpg?alt=media&token=f09a1837-1695-4c2d-b4fd-604757ca5022'},
        {name: 'Robert', delta: new Decimal(0.0), issuer: false, opponent: false, pic: 'https://firebasestorage.googleapis.com/v0/b/whist-application.appspot.com/o/robert.jpg?alt=media&token=8651e70f-5860-4eac-8e8b-1b3124a45912'},
        {name: 'Lydia', delta: new Decimal(0.0), issuer: false, opponent: false, pic: 'https://firebasestorage.googleapis.com/v0/b/whist-application.appspot.com/o/bomma.jpg?alt=media&token=d8139635-0af7-492e-a1e5-59c790c674bb'},
        {name: 'Marc', delta: new Decimal(0.0), issuer: false, opponent: false, pic: 'https://firebasestorage.googleapis.com/v0/b/whist-application.appspot.com/o/marc.jpg?alt=media&token=fdda08a8-6269-4fd6-9486-d4861297943c'}
    ])
    const [user, setUser] = useState(() => {
        const u = sessionStorage.getItem('whist-user');
        return u !== null ? u : null;
    });

    return (
        <UserContext.Provider value={user}>
            <div className='min-h-screen flex flex-col'>
                <header className='sticky top-0 z-50'>
                    {user !== null ? <Navbar setUser={setUser} setPlayers={setPlayers}/> : <></>}
                </header>

                {/* for centering vertically and horizontally: flex-grow flex items-center justify-center text-center */}
                <main className="flex-grow flex justify-center text-center mt-2">
                    <Routes>
                        <Route path='/' element={<Navigate to='/login'/>}/>
                        <Route path='/login' element={user !== null ? <Navigate to='/main-screen'/> : <Login setUser={setUser}/>}/>
                        <Route path='/main-screen' element={user !== null ? <Main players={players} setPlayers={setPlayers}/> : <Navigate to='/login'/>}/>
                        <Route path='/new-game' element={user !== null ? <RegisterNewGame players={players} setPlayers={setPlayers}/> : <Navigate to='/login'/>}/>
                        <Route path='/stats/:name' element={user !== null ? <PlayerStatistics players={players}/> : <Navigate to='/login'/>}/>
                    </Routes>
                </main>
            </div>
        </UserContext.Provider>
    )
}

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {facebookLogin, googleLogin, login} from "../utils/auth";

export default function ({setUser}) {
    const navigate = useNavigate();
    const [email, updateEmail] = useState('');
    const [password, updatePassword] = useState('');
    const [valid, setValid] = useState(true);
    const [authMessage, setAuthMessage] = useState('');

    const submit = async event => {
        event.preventDefault();

        try {
            setUser(await login(email, password, navigate));
        } catch (e) {
            setValid(false);
            setAuthMessage(e.message)
        }
    }

    const googleSubmit = async event => {
        event.preventDefault();

        await googleLogin();
    }

    const facebookSubmit = async event => {
        event.preventDefault();

        await facebookLogin();
    }

    return (
        <div className="w-full mx-2 p-6 m-auto bg-white rounded-md shadow-md ring-2 ring-gray-800/50 lg:max-w-lg items-center ">
            <h1 className="text-3xl font-semibold text-center text-gray-700">Whist App</h1>
            <form id='login-form' className="space-y-4">
                <div>
                    <label className="label">
                        <span className="text-base label-text">Email</span>
                    </label>
                    <input value={email} onChange={v => {
                        updateEmail(v.target.value);
                    }}
                           type="text" placeholder="Email Address" className="w-full input input-bordered"/>
                </div>
                <div>
                    <label className="label">
                        <span className="text-base label-text">Password</span>
                    </label>
                    <input value={password} onChange={v => {
                        updatePassword(v.target.value);
                    }}
                           type="password" placeholder="Enter Password"
                           className="w-full input input-bordered"/>
                </div>
                <a className="text-xs text-gray-600 hover:underline hover:text-blue-600">Forgot Password?</a>
                <div>
                    <button type='submit' className="btn btn-block" onClick={submit}>Login</button>
                </div>
                <div className="divider">OR</div>
                <div>
                    <button type='submit' className="btn" onClick={googleSubmit}>
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/></svg>
                        Google
                    </button>
                </div>
                {
                    !valid ?
                        <div className="alert alert-error">
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6"
                                 fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                            </svg>
                            <span>{authMessage}</span>
                        </div>
                        :
                        <></>
                }
            </form>
        </div>
    )
}

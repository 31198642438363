import React from 'react';
import {
    signInWithPopup,
    signInWithEmailAndPassword,
    signOut,
    GoogleAuthProvider,
    FacebookAuthProvider
} from "firebase/auth";
import {auth} from "../index";

let user = null;
const user_name_storage = 'whist-user';

export const UserContext = React.createContext();

export const login = async (email, password, navigateCallback) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        user = auth.currentUser;
        sessionStorage.setItem(user_name_storage, user)
        navigateCallback('/main-screen')
    } catch (error) {
        throw new AuthError(error.code);
    }
    return user;
}

export const facebookLogin = async () => {
    const provider = new FacebookAuthProvider();
    try {
        const result = await signInWithPopup(auth, provider);
        console.log(result.user);
    } catch (e) {
        console.log(e);
    }
}

export const googleLogin = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    signInWithPopup(auth, provider)
        .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        alert('login works?')
    }).catch((error) => {
        console.log(error)
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        alert(errorMessage)
        // ...
    });
}

export const logout = async () => {
    try {
        await signOut(auth);
        user = null;
        sessionStorage.removeItem(user_name_storage)
        console.log(auth.currentUser);
    } catch (e) {
        console.log(e);
    }
}

export class AuthError extends Error {
    constructor(errorType){
        let message = ''
        switch(errorType){
            case 'auth/user-not-found':
                message = 'It seems that you don\'t have an account. Contact your administrator.';
                break;
            case 'auth/wrong-password':
                message = 'Invalid username or password. Try Again.';
                break;
            default:
                message = 'Something went wrong when logging in. Please try again later.';
        }
        super(message);
    }
}

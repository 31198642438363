import {useNavigate} from 'react-router-dom';
import {logout} from "../utils/auth";
import {ArrowLeftStartOnRectangleIcon, ArrowPathIcon} from "@heroicons/react/24/outline";
import {refreshScores} from "../utils/firestore";

export default function Navbar({setUser, setPlayers}) {
    const nav = useNavigate();

    return (
        <div className="navbar bg-neutral text-neutral-content">
            <div className="navbar-start">
                <button className="btn btn-outline text-white" onClick={() => nav('/new-game')}>Nieuw spel</button>
            </div>
            <div className="navbar-center">
                <a className="btn btn-ghost normal-case text-xl" onClick={() => nav('main-screen')}>Whist</a>
            </div>
            <div className="navbar-end">
                <button className="btn btn-neutral" onClick={async () => await refreshScores(setPlayers)}>
                    <ArrowPathIcon className="w-6"/>
                </button>
                <button className="btn btn-neutral" onClick={async () => {await logout(); setUser(null); nav('/login');}}>
                    <ArrowLeftStartOnRectangleIcon className="w-6"/>
                </button>
            </div>
        </div>
    )
}

import {GameType} from "./GameType";

export class Game {
    private readonly name: string;
    private readonly scores: Record<number, number>;
    private gameType: GameType;

    constructor(name: string, scores: Record<number, number>, gameType: GameType) {
        this.name = name;
        this.scores = scores;
        this.gameType = gameType;
    }

    get getName(){
        return this.name;
    }

    get getGameType(){
        return this.gameType;
    }

    getScoreByTricks(tricks: number) : number {
        return this.scores[tricks];
    }
}

import {addDoc, collection, doc, getDoc, getDocs, orderBy, query, setDoc, where} from 'firebase/firestore';
import {db} from '../index.tsx';
import {Decimal} from "decimal.js";

const games = 'games';
const endscores = 'endscores';

export async function createGame(playedGame) {
    console.log(playedGame)
    try {
        const docRef = await addDoc(collection(db, games), playedGame);
        console.log("Document written with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

function getCurrentScoresDocRef() {
    const currentDate = new Date().toDateString();
    return doc(db, endscores, currentDate);
}

async function getCurrentScores() {
    const docRef = getCurrentScoresDocRef();
    const doc = await getDoc(docRef);
    return doc.data();
}

export async function refreshScores(setPlayers) {
    getCurrentScores().then(data => {
        setPlayers((prevPlayers) => {
            return prevPlayers.map(player => {
                const delta = data === undefined ? 0.0 : data[player.name.toLowerCase()];
                return {...player, delta: new Decimal(delta)}
            });
        });
    });
}

export async function getScoresAndGamesOfPlayer(player) {
    const today_start = new Date();
    today_start.setHours(0,0,0);

    const today_end = new Date();
    today_end.setHours(23,59,59);


    const q = query(
        collection(db, games),
        where('time', '>=', today_start),
        where('time', '<=', today_end),
        orderBy('time', 'asc')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs
        .filter(doc => doc.data()[player] !== 0)
        .map(g => {
            return {
                score: g.data()[player],
                game: g.data().game
            }
        });
}

export async function updateCurrentScores(players) {
    const currentDate = new Date().toDateString();
    try {
        await setDoc(doc(db, endscores, currentDate), players);
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

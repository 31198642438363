import {useState} from "react";
import {Decimal} from "decimal.js";
import {GameType} from "../domain/GameType";
import Game from "../components/Game";
import Players from "../components/Players";
import Tricks from "../components/Tricks";
import {useNavigate} from "react-router-dom";
import {createGame, updateCurrentScores} from "../utils/firestore";
import {toast} from "react-toastify";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/outline";

export default function RegisterNewGame({players, setPlayers}) {
    const nav = useNavigate();
    const [step, setStep] = useState(1);
    const [tricks, setTricks] = useState(0);
    const [currentGame, setCurrentGame] = useState(null);
    const [issuers, setIssuers] = useState([]);
    const [opponents, setOpponents] = useState([]);
    const [isIllegal, setIsIllegal] = useState(false);

    function isIssuer(player) {
        return issuers.map(p => p.name).includes(player.name);
    }

    function isOpponent(player) {
        return opponents.map(p => p.name).includes(player.name);
    }

    function updateEndScores(updatedPlayers) {
        const copy = [...updatedPlayers]
            .map(copy => ({[copy.name.toLowerCase()]: Number(copy.delta.toFixed(2))}))
            .reduce((acc, curr) => {
                return {...acc, ...curr};
            }, {});
        copy.last_updated_time = new Date();
        updateCurrentScores(copy).then(r => console.log(r));
    }

    function addNewGame(copy_players, game) {
        const db_object = copy_players
            .map(copy => ({[copy.name.toLowerCase()]: copy.delta}))
            .reduce((acc, curr) => {
                return {...acc, ...curr};
            }, {});
        db_object.time = new Date();
        db_object.game = game.name;
        createGame(db_object).then(r => console.log(r));
    }

    function modifyDeltasByPlayedGame(game, tricks) {
        const score = new Decimal(game.getScoreByTricks(tricks));
        const copy_players = JSON.parse(JSON.stringify(players));
        setPlayers((prevPlayers) => {
            const updatedPlayers = prevPlayers.map(player => {
                // Issuer(s)
                if (isIssuer(player)) {
                    const delta = game.getGameType === GameType.one_v_three ? Decimal.mul(3, score) : score
                    copy_players.filter(p => p.name === player.name)[0].delta = Number(delta.toFixed(2));
                    return {...player, delta: player.delta.add(delta)}
                }
                // Opponents
                else if (isOpponent(player)) {
                    const delta = Decimal.mul(-1, score);
                    copy_players.filter(p => p.name === player.name)[0].delta = Number(delta.toFixed(2));
                    return {...player, delta: player.delta.add(delta)}
                }
                // Unchanged
                copy_players.filter(p => p.name === player.name)[0].delta = Number(0);
                return player;
            });
            updateEndScores(updatedPlayers)
            addNewGame(copy_players, game);
            return updatedPlayers;
        });
    }

    function h1Message() {
        switch (step) {
            case 1:
                return 'Welk spel is er gespeeld?';
            case 2:
                return 'Wie waren de spelers?';
            case 3:
                return 'Hoeveel slagen zijn er behaald?';
            default:
                return '';
        }
    }

    function errorMessage() {
        switch (step) {
            case 1:
                return 'Select a game!';
            case 2:
                return 'Enter the players';
            case 3:
                return 'Enter the tricks';
            default:
                return '';
        }
    }

    const handleIllegalAction = () => {
        setIsIllegal(true);

        setTimeout(() => {
            setIsIllegal(false);
        }, 1000);
    };

    function renderStepComponent() {
        switch (step) {
            case 1:
                return <Game currentGame={currentGame} setGame={setCurrentGame}/>;
            case 2:
                return <Players players={players} setPlayers={setPlayers} issuers={issuers} setIssuers={setIssuers} isIssuer={isIssuer}
                                opponents={opponents} setOpponents={setOpponents} isOpponent={isOpponent}/>;
            case 3:
                return <Tricks tricks={tricks} setTricks={setTricks}/>;
            default:
                return <></>;
        }
    }

    function haveCommonElements(list1, list2) {
        const set1 = new Set(list1);
        return list2.some(item => set1.has(item));
    }

    function reset(){
        setCurrentGame(null);
        setTricks(0);
        setIssuers([]);
        setOpponents([]);
    }

    function loadPreviousPage() {
        if (step > 1) setStep(step - 1)
        if (step === 1) nav('/main-screen')
    }

    function loadNextPage() {
        switch (step) {
            case 1: {
                return currentGame !== null ? setStep(step + 1) : handleIllegalAction();
            }
            case 2: {
                if (haveCommonElements(issuers.map(p => p.name), opponents.map(p => p.name))) {
                    handleIllegalAction();
                    return
                }
                switch (currentGame?.gameType) {
                    case GameType.one_v_three: {
                        return (issuers.length === 1 && opponents.length === 3) ? setStep(step + 1) : handleIllegalAction();
                    }
                    case GameType.two_v_two: {
                        return (issuers.length === 2 && opponents.length === 2) ? setStep(step + 1) : handleIllegalAction();
                    }
                    default:
                        return;
                }
            }
            case 3: {
                let undoClicked = false;

                const handleUndo = () => {
                    undoClicked = true;
                    toast.dismiss();
                }

                if (currentGame !== null) {
                    const resolveAfter3Sec = new Promise(resolve => {
                        setTimeout(() => {
                            if (!undoClicked) modifyDeltasByPlayedGame(currentGame, tricks);
                            resolve();
                        }, 3000);
                    });
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: {
                                render() {
                                    return (
                                        <div className="flex items-center justify-between gap-x-3">
                                            <p>Spel opslaan...</p>
                                            <button className="btn btn-ghost" onClick={handleUndo}>
                                                <ArrowUturnLeftIcon className="w-6"/>
                                            </button>
                                        </div>
                                    );
                                },
                                icon: true
                            },
                        }
                    );
                }
                reset();
                nav('/main-screen');
                return;
            }
            default:
                return;
        }
    }

    return (
        <div>
            <ul className="steps">
                <li className={`step ${step >= 1 ? 'step-primary' : ''}`}>Spel</li>
                <li className={`step ${step >= 2 ? 'step-primary' : ''}`}>Spelers</li>
                <li className={`step ${step >= 3 ? 'step-primary' : ''}`}>Slagen</li>
            </ul>
            <article className="prose mt-2">
                <h2 className={`${isIllegal ? 'animate-bounce' : ''}`}>{h1Message()}</h2>
            </article>
            <div className='mt-2'>
                {renderStepComponent()}
            </div>
            <div className="join join-horizontal mt-2">
                <div>
                    <button className="btn btn-success join-item mx-2" onClick={loadPreviousPage}>
                        {step === 1 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 384 512">
                                <path
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 320 512">
                                <path
                                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/>
                            </svg>
                        }
                    </button>
                </div>
                <div>
                    <button className="btn btn-success join-item mx-2" onClick={loadNextPage}>
                        {step === 3 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512">
                                <path
                                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 320 512">
                                <path
                                    d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                            </svg>
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

import {Game} from "./Game";
import {GameType} from "./GameType";

const games = [
    new Game('Alleen gaan', {
        0: -0.5,
        1: -0.25,
        2: -0.2,
        3: -0.15,
        4: -0.1,
        5: 0.05,
        6: 0.1,
        7: 0.15,
        8: 0.2,
        9: 0.25,
        10: 0.3,
        11: 0.35,
        12: 0.4,
        13: 0.5
    }, GameType.one_v_three),
    new Game('Vraag en meegaan', {
        0: -0.45,
        1: -0.4,
        2: -0.4,
        3: -0.35,
        4: -0.3,
        5: -0.25,
        6: -0.2,
        7: -0.15,
        8: 0.1,
        9: 0.15,
        10: 0.2,
        11: 0.25,
        12: 0.3,
        13: 0.45
    }, GameType.two_v_two),
    new Game('Abondance', {
        0: -0.35,
        1: -0.35,
        2: -0.35,
        3: -0.35,
        4: -0.35,
        5: -0.35,
        6: -0.35,
        7: -0.35,
        8: -0.35,
        9: 0.35,
        10: 0.35,
        11: 0.35,
        12: 0.35,
        13: 0.35
    }, GameType.one_v_three),
    new Game('Miserie', {
        0: 0.5,
        1: -0.5,
        2: -0.5,
        3: -0.5,
        4: -0.5,
        5: -0.5,
        6: -0.5,
        7: -0.5,
        8: -0.5,
        9: -0.5,
        10: -0.5,
        11: -0.5,
        12: -0.5,
        13: -0.5
    }, GameType.one_v_three),
    new Game('Trull', {
        0: -0.3,
        1: -0.3,
        2: -0.3,
        3: -0.3,
        4: -0.3,
        5: -0.25,
        6: -0.2,
        7: -0.15,
        8: 0.10,
        9: 0.15,
        10: 0.2,
        11: 0.25,
        12: 0.3,
        13: 0.5
    }, GameType.two_v_two),
    new Game('Miserie op tafel', {
        0: 1,
        1: -1,
        2: -1,
        3: -1,
        4: -1,
        5: -1,
        6: -1,
        7: -1,
        8: -1,
        9: -1,
        10: -1,
        11: -1,
        12: -1,
        13: -1
    }, GameType.one_v_three),
    new Game('Solo-Slim', {
        0: -10,
        1: -10,
        2: -10,
        3: -10,
        4: -10,
        5: -10,
        6: -10,
        7: -10,
        8: -10,
        9: -10,
        10: -10,
        11: -10,
        12: -10,
        13: 10
    }, GameType.one_v_three)
]

export default games;

export default function ({players, setPlayers, issuers, setIssuers, isIssuer, opponents, setOpponents, isOpponent}) {
    return (
        <div className="flex w-full">
            <div className="join join-vertical grid flex-grow rounded-box">
                {
                    players.map(player => {
                        return (
                            <button key={`issuers${player.name}`}
                                    onClick={() => {
                                        const checked = isIssuer(player)
                                        const index = issuers.indexOf(player);
                                        if (checked) {
                                            issuers.splice(index, 1)
                                            setIssuers([...issuers])
                                        } else {
                                            setIssuers([...issuers, player])
                                        }
                                    }}
                                    className={`join-item btn my-2 ${isIssuer(player) ? 'btn-primary' : 'btn-neutral'}`}
                                    name="options"
                                    aria-label={player.name}>{player.name}</button>
                        )
                    })}
            </div>
            <div className="divider divider-horizontal"/>
            <div className="join join-vertical grid flex-grow rounded-box">
                {
                    players.map(player => {
                        return (
                            <button key={`opponents${player.name}`}
                                    onClick={() => {
                                        const checked = isOpponent(player)
                                        const index = opponents.indexOf(player);
                                        if (checked) {
                                            opponents.splice(index, 1)
                                            setOpponents([...opponents])
                                        } else {
                                            setOpponents([...opponents, player])
                                        }
                            }}
                                    className={`join-item btn my-2 ${isOpponent(player) ? 'btn-primary' : 'btn-neutral'}`}
                                    name="options"
                                    aria-label={player.name}>{player.name}</button>
                        )
                    })}
            </div>
        </div>
    )
}

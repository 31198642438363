import {useNavigate, useParams} from "react-router-dom";
import {ArrowLeftIcon} from "@heroicons/react/24/outline";
import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {getScoresAndGamesOfPlayer} from "../utils/firestore";
import {useEffect, useState} from "react";

function PlayerStatistics() {
    const navigate = useNavigate();
    const {name: player_name} = useParams();
    const [playerGames, setPlayerGames] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            const data = await getScoresAndGamesOfPlayer(player_name);
            setPlayerGames(data);
            setLoading(false);
        }
        fetchData();
    }, [player_name]);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                title: {
                    color: 'blue'
                }
            },
            title: {
                display: true,
                text: 'Spelverloop',
            },
        },
        maintainAspectRatio: false
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const labels = playerGames.map(game => game.game);

    const getBackgroundColorMarker = (score) => {
        if (score > 0) return '#36D399';
        else return '#F87272';
    }

    const data = {
        labels,
        datasets: [
            {
                label: player_name,
                data: playerGames.map(game => game.score).reduce((acc, score) => {
                    const lastValue = acc.length > 0 ? acc[acc.length - 1] : 0;
                    const currentSum = lastValue + score;
                    acc.push(currentSum);
                    return acc;
                }, []),
                borderColor: 'rgb(41,53,64)',
                // backgroundColor:
                backgroundColor: 'rgb(41,53,64)',
                pointBackgroundColor: playerGames.map(game => getBackgroundColorMarker(game.score)),
                pointBorderColor: playerGames.map(game => getBackgroundColorMarker(game.score)),
                pointBorderWidth: 3
            }
        ],
    };

    return <div className="items-center">
        {/*<ArrowLeftIcon className="w-6" onClick={() => navigate(-1)}/>*/}
        <Line
            options={options}
            data={data}
        />
    </div>
}

export default PlayerStatistics;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.min.css';
import Toast from './components/Toast';

const firebaseConfig = {
    apiKey: 'AIzaSyB0WjsPxfWDZERjUb_6jMLuJ_ho6pjy0aM',
    authDomain: 'whist-application.firebaseapp.com',
    projectId: 'whist-application',
    storageBucket: 'whist-application.appspot.com',
    messagingSenderId: '789467846745',
    appId: '1:789467846745:web:7be5aa7e8485bcb16bb948',
    measurementId: 'G-WYHXQRQWEC'
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <>
        <App/>
        <Toast/>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

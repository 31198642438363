import {useEffect} from "react";
import {refreshScores} from "../utils/firestore";
import {useNavigate} from "react-router-dom";

export default function Main({players, setPlayers}) {
    const navigate = useNavigate();
    useEffect(() => {
        refreshScores(setPlayers);
    }, [setPlayers]);

    function getDeltaString(delta) {
        if (delta.isZero())
            return <span>€ {delta.toString().replace('.', ',')}</span>
        return delta.isPositive() ?
            <span className='text-success'>€ {delta.toString().replace('.', ',')}</span> :
            <span className='text-error'>€ {delta.toString().replace('.', ',')}</span>
    }

    function onPlayerClickedHandler(player) {
        navigate(`/stats/${player.name.toLowerCase()}`);
    }

    return <div className="flex items-center">
        <div>
            {players.slice(0, 3).map(player => {
                return (
                    <div key={player.name} className="card card-side bg-base-100 shadow-xl mx-1 my-5"
                         onClick={() => onPlayerClickedHandler(player)}>
                        <figure><img className="ml-1 w-12 rounded-full" src={player.pic} alt="profile"/></figure>
                        <div className="card-body">
                            <h2 className="card-title">{getDeltaString(player.delta)}</h2>
                            <p>{player.name}</p>
                        </div>
                    </div>
                )
            })}
        </div>
        <div>
            {players.slice(3, 6).map(player => {
                return (
                    <div key={player.name} className="card card-side bg-base-100 shadow-xl mx-1 my-5"
                         onClick={() => onPlayerClickedHandler(player)}>
                        <figure><img className="ml-1 w-12 rounded-full" src={player.pic} alt="profile"/></figure>
                        <div className="card-body">
                            <h2 className="card-title">{getDeltaString(player.delta)}</h2>
                            <p>{player.name}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
}
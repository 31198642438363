import games from "../domain/Games";

export default function ({tricks, setTricks}) {
    return (
        <div>
            <article className="prose mt-8">
                <h1>{tricks}</h1>
            </article>
            <input type="range" min={0} max='13' defaultValue='0' className="range range-lg" step='1'
                   onChange={(event) =>
                       setTricks(Number(event.target.value))
                   }/>
            <div className="w-full flex justify-between text-xs px-2">
                <span>|</span>
                <span>|</span>
                <span>|</span>
                <span>|</span>
                <span>|</span>
                <span>|</span>
                <span>|</span>
                <span>|</span>
                <span>|</span>
                <span>|</span>
            </div>
        </div>
    )
}

import {ToastContainer} from 'react-toastify';

function Toast() {
    return <ToastContainer
        position={'top-center'}
        closeOnClick={false}
        closeButton={false}
    />;
}

export default Toast;
import games from "../domain/Games";

export default function ({currentGame, setGame}) {
    return (
        <div className="join join-vertical">
            {games.map(game => {
                return <input key={game.name}
                              onClick={() => setGame(game)}
                              className={`join-item btn my-2 ${currentGame?.name === game.name ? 'btn-primary' : 'btn-neutral'}`}
                              type="radio"
                              name="options"
                              aria-label={game.name} />
            })}
        </div>
    )
}
